const statusMap = [
    {value: null, text: 'Выберите статус'},
    {value: 'registered', text: 'Зарегистрировано в системе агента'},
    {value: 'transit', text: 'В пути от агента к субагенту'},
    {value: 'received', text: 'Принято субагентом'},
    {value: 'delivered', text: 'Доставлено получателю'},
    {value: 'part_delivered', text: 'Частично доставлено получателю'},
    {value: 'canceled', text: 'Заказ отменен (отказ получателя)'},
    {value: 'returned', text: 'Заказ отправлен субагентом агенту'},
    {value: 'lost', text: 'Заказ утерян'}
];


export default {
    translateStatus(value) {
        switch (value) {
            case 'registered':
                return 'Зарегистрировано в системе агента';
            case 'transit':
                return 'В пути от агента к субагенту';
            case 'received':
                return 'Принято субагентом';
            case 'delivered':
                return 'Доставлено получателю';
            case 'part_delivered':
                return 'Частично доставлено получателю';
            case 'canceled':
                return 'Заказ отменен (отказ получателя)';
            case 'returned':
                return 'Заказ отправлен субагентом агенту';
            case 'lost':
                return 'Заказ утерян';
        }
        return value;
    },
    translateSettingType(value) {
        switch (value) {
            case 'DADATA_TOKEN':
                return 'Токен для доступа к Dadata';
        }
        return value;
    },
    prettyGeography(geo) {
        if (!geo) return '';
        let result = '';
        if (geo.country)
            result += geo.country;
        if (geo.region)
            result += ', ' + geo.region;
        if (geo.city && geo.city !== geo.region)
            result += ', ' + geo.city;
        if (geo.area)
            result += ', ' + geo.area;
        if (geo.settlement)
            result += ', ' + geo.settlement;
        return result.replace("Россия, ", "");
    },
    prettyAddress(address) {
        if (!address) return '';
        let result = '';
        if (address.street)
            result += address.street;
        if (address.house)
            result += ' ' + address.house;
        if (address.block)
            result += ', корп.' + address.block;
        if (address.building)
            result += ', стр.' + address.building;
        if (address.flat)
            result += ', кв.' + address.flat;
        return result;
    },
    translateGeographyAndAddress(geo, address) {
        return this.translateGeography(geo) + (address ? ' (' + this.translateAddress(address) + ')' : "");
    },
    formatDate(value) {
        if (value === undefined || value === null || value.trim() === '') {
            return '';
        } else {
            const parts = value.match(/(\d+)/g);
            return parts[2] + '.' + parts[1] + '.' + parts[0];
        }
    },
    statusMap() {
        return statusMap;
    },

    courierCompanies() {
        return [
            "SPSR",
            "CityExpress",
            "PickPoint",
            "IML",
            "Hermes",
            "CDEK",
            "Grastin",
            "Boxberry",
            "DPD",
            "GD",
            "RussianPost",
            "RussianPostApi",
            "RussianPostApiEcom",
            "MaxiPost",
            "PickUp",
            "PonyExpress",
            "YandexDelivery",
            "CSE",
            "DOSTAVISTA",
            "PEK",
            "TEMPOLINE_RUSSIAN_POST",
            "STRIZH",
            "SelfPickup", // для отображения пвз клиетов
            "Logsis",
            "A1",
            "OZON",
            "DalliService",
            "PbmLatvia"
        ];
    },
}