<template>
    <b-container fluid>
        <b-row>
            <b-col class="pl-0">
                <b-button variant="link" @click="saveSettings()">Сохранить изменения</b-button>
            </b-col>
        </b-row>
        <b-alert variant="success" :show="showAlert">Изменения сохранены</b-alert>
        <b-alert variant="danger" dismissible @dismissed="error = null; showError = false" :show="showError">{{ error
            }}
        </b-alert>
        <b-card no-body>
            <b-tabs pills card>
                <b-tab title="Основные">
                    <b-row>
                        <b-col>
                            <b-col cols="12">
                                <b-form-group label="Интеграция">
                                    <b-form-checkbox v-model="setting.integrationEnabled"
                                                     @change="change('INTEGRATION_ENABLED',$event)"/>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12">
                                <b-form-group label="Тестовая интеграция">
                                    <b-form-checkbox v-model="setting.testIntegration"
                                                     @change="change('TEST_INTEGRATION',$event)"/>
                                </b-form-group>
                            </b-col>
                        </b-col>
                        <b-col>
                        </b-col>
                    </b-row>
                </b-tab>
                <b-tab title="Подсказки">
                    <b-row>
                        <b-col>
                            <b-col cols="12">
                                <b-form-group label="Использовать Dadata">
                                    <b-form-checkbox v-model="setting.dadataEnabled"
                                                     @change="change('DADATA_ENABLED',$event)"/>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12">
                                <b-form-group label="Токен для доступа к Dadata">
                                    <b-form-input v-model="setting.dadataToken"
                                                  @change="change('DADATA_TOKEN',$event)"/>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12">
                                <b-form-group label="Секретный ключ для доступа к Dadata">
                                    <b-form-input v-model="setting.dadataSecretKey"
                                                  @change="change('DADATA_SECRET_KEY',$event)"/>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12">
                                <b-form-group label="Dadata ФИАС URL">
                                    <b-form-input v-model="setting.dadataFiasUrl"
                                                  @change="change('DADATA_FIAS_URL',$event)"/>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12">
                                <b-form-group label="Dadata URL стандартизации">
                                    <b-form-input v-model="setting.dadataStandartizationUrl"
                                                  @change="change('DADA_STANDARDIZATION_URL',$event)"/>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12">
                                <b-form-group label="Dadata URL геолокации">
                                    <b-form-input v-model="setting.dadataGeoLocateUrl"
                                                  @change="change('DADATA_GEO_LOCATE_URL',$event)"/>
                                </b-form-group>
                            </b-col>
                        </b-col>
                        <b-col>
                            <b-col cols="12">
                                <b-form-group label="Ключ для доступа к сервисам Google ">
                                    <b-form-input v-model="setting.googleApiKey"
                                                  @change="change('GOOGLE_API_KEY',$event)"/>
                                </b-form-group>
                            </b-col>
                        </b-col>
                    </b-row>
                </b-tab>
            </b-tabs>
        </b-card>
    </b-container>
</template>

<script>
    import translator from '../repository';
    import api from '../api';

    export default {
        name: "SystemSettings",
        created() {
            this.getSettings();
        },
        data() {
            return {
                settings: [],
                changedSettings: [],
                error: null,
                showError: false,
                showAlert: false,
                setting: {
                    dadataEnabled: false,
                    dadataToken: null,
                    dadataSecretKey: null,
                    dadataUrl: null,
                    dadataFiasUrl: null,
                    dadataStandartizationUrl: null,
                    dadataGeoLocateUrl: null,
                    googleApiKey: null,
                    integrationEnabled: false,
                    testIntegration: false,
                }
            }
        },
        methods: {
            translate(key) {
                return translator.translateSettingType(key);
            },
            saveSettings() {
                this.changedSettings.forEach(setting => this.save(setting));
                this.showAlert = true;
                setTimeout(() => this.showAlert = false, 3000);

            },
            save(setting) {
                this.$http.post(this.$root.apiUrl + api.settings.save, setting, {headers: this.$root.getHeaders()})
                    .then(response => {
                        if (!response.body.success) {
                            this.error = response.body.errors.map(error => error.message).reduce((acc, value) => acc + '<br>' + value);
                            this.showError = true;
                        }
                    }, response => {
                        this.$root.handleErrorResponse(response);
                    });
            }
            ,
            getSettings() {
                this.$http.get(this.$root.apiUrl + api.settings.getAll, {headers: this.$root.getHeaders()})
                    .then(response => {
                        if (response.body.success) {
                            let settings = response.body.list;
                            this.setting.dadataEnabled = settings.find(value => value.key === 'DADATA_ENABLED').value;
                            this.setting.dadataToken = settings.find(value => value.key === 'DADATA_TOKEN').value;
                            this.setting.dadataSecretKey = settings.find(value => value.key === 'DADATA_SECRET_KEY').value;
                            this.setting.dadataUrl = settings.find(value => value.key === 'DADATA_URL').value;
                            this.setting.dadataFiasUrl = settings.find(value => value.key === 'DADATA_FIAS_URL').value;
                            this.setting.dadataStandartizationUrl = settings.find(value => value.key === 'DADA_STANDARDIZATION_URL').value;
                            this.setting.dadataGeoLocateUrl = settings.find(value => value.key === 'DADATA_GEO_LOCATE_URL').value;
                            this.setting.googleApiKey = settings.find(value => value.key === 'GOOGLE_API_KEY').value;
                            this.setting.integrationEnabled = settings.find(value => value.key === 'INTEGRATION_ENABLED').value;
                            this.setting.testIntegration = settings.find(value => value.key === 'TEST_INTEGRATION').value;
                        } else {
                            this.error = response.body.errors.map(error => error.message).reduce((acc, value) => acc + '<br>' + value);
                            this.showError = true;
                        }
                    }, response => {
                        this.$root.handleErrorResponse(response);
                    });
            }
            ,

            change(key, value) {
                let index = this.changedSettings.findIndex(value1 => value1.key === key);
                // eslint-disable-next-line no-console
                console.log(index);
                if (index >= 0) {
                    this.changedSettings.splice(index, 1);
                }

                this.changedSettings.push({key: key, value: value});
            }
        }
    }
</script>

<style scoped>
</style>