export default {
    shipments: {
        shipment: {
            getAll: '/api/v2/shipment-requests',
        },
        place: {},
    },
    contract: {
        calculateTariff: '/api/v2/calculateContractTariff',
        getTariffs: '/api/getTariffs',
        tariffImport: {
            getAll: '/api/v2/tariff-import-processes',
        }
    },
    settings: {
        getAll: '/api/v2/system-settings',
        save: '/api/v2/system-setting'
    },
    cdek: {
        citiCodes: {
            getAll: '/api/v2/cdek/cityCodes',
            update: '/api/v2/cdek/updateCityCodes',
        }
    }


}